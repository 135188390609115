<template>
<div class="fill" style="overflow: hidden">
    <div class="fill flex main-container totem" v-if="local && accessCodeOk">

        <!-- Left Menu -->
        <div class="left-menu l-menu">
            
            <div class="ta-center l-local-logo" v-if="local.logotype">
                <img :src="Util.img(local.logotype, 'logos')" style="width: 150px; margin: 10px 0px" class="logo">
            </div>

            <div class="flex-fill flex column l-scroll-container" style="overflow-y: auto; overflow-x: hidden; padding-bottom: 20px">

                <div class="flex column l-menu-sections" style="margin-bottom: 20px">
                    <div :class="`btn l-entry l-menu-section-title l-menu-section-${section.title} ${section.unavailable ? 'section-unavailable' : ''}`" style="margin-top: 10px" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menu`">{{section.title}}</div>
                </div>
            </div>
            <!--
            <div class="separator l-separator l-separator-bottom"></div>
            <a href="https://influyeapp.cl" target="_blank" class="ta-left s3 txt-hover l-powered-by">Powered by Influye</a>
            -->
        </div>

        <!-- Center -->
        <div class="center-content ofy bg" ref="panelCenter" id="menu-container">
            <div>

                <!--banner -->
                <div class="relative">
                    <div class="flex column relative c-banner" v-show="banner" :style="{height: mobile ? '150px' : '200px', 'background-image': banner ? `url('${Util.img(banner, 'banners')}` : '', 'background-size': 'cover', 'background-position': 'center center'}">
                    </div>
                    
                    <swiper v-if="carousel && carousel.length" class="swiper c-carousel" :options="{
                        loop: true,
                        grabCursor: true,
                        autoplay: true,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true
                        },
                    /*
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                    */
                    }"
                    style="cursor: grab;">
                        <swiper-slide v-for="entry in carousel" :key="entry.image" class="c-carousel-slide">
                            <img class="c-carousel-image" :src="Util.img(entry.image, 'banners')" alt="" style="width: 100%">
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <!--
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                        -->
                    </swiper>

                </div>

                <div v-show="false" class="relative flex fc c-search-bar" id="search-bar" style="overflow: none; padding: 10px; top: 0px; background-color: var(--center-bg)">
                    <!--
                    <i v-show="isSearchInputVisible" class="icon icon-search c2 s2 absolute" style="transform: scale(1); margin: 0; left: 12px; top: 12px; font-size: 20px; pointer-events: none"></i>
                    <input ref="searchInput" v-model="searchItem" @focus="searchFocused=true" @blur="searchFocused=false" type="text" class="s2 c1" :style="{'flex-shrink': 0, width: '100%', 'max-width': (isSearchSectionsVisible) && (!searchItem && !searchFocused) ? '20px' : '', padding: '15px', 'padding-left': '40px'}" placeholder="Buscar..." title="Buscar items del menú">
                    <i v-show="(searchItem || searchFocused) && isSearchInputVisible" class="icon icon-x absolute btn-hover c2" style="right: 10px; font-size: 25px; top: 10px; transform: scale(1);" @click="searchItem = ''"></i>
                    -->
                    <div ref="searchSections" class="flex align-center flex-fill c-menu-section-container" style="flex-wrap: nowrap; overflow: auto">
                        <div :class="`btn c-menu-section-entry c-menu-section-entry-${section.title.replace(/ /g, '')}`" style="flex-shrink: 0; padding: 20px; margin: 10px" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menú`"><div>{{section.title}}</div></div>
                    </div>
                </div>

                <menu-items ref="menuItems"
                :menu="filteredMenu" 
                :search="searchItem" 
                :analytics="$refs.analytics"
                :itemPriceDisplay="itemPriceDisplay"
                itemImageSize="SMALL"
                :canOpenItems="true"
                :canAddItems="true"
                :behaviour="menuDisplay"
                @ClearSearch="searchItem=''"
                :hideModalTitle="true"
                @OnAdd="AddItem($event.item, $event.key)"
                @OnRemove="$delete(myOrder, $event.key)"
                :overwriteOnOpen="CheckIfItemIsUniquePerOrder"></menu-items>

            </div>
        </div>

    </div>

    <div class="right-menu bottom-checkout" v-if="local && accessCodeOk">
        
        <div class="btn flex align-center" @click="Finish()">Volver al inicio</div>

        <div class="flex-fill"></div>

        <div :class="`btn btn-hover invert flex ${total.items ? '' : 'c2'}`" ref="btn-continue" @click="OpenOrderConfirm();$refs.analytics.Trigger('view_cart')" title="Continuar con mi pedido" style="width: 50%">
            <div class="flex column flex-fill">
                <div class="c-btn-title">Continuar con mi Pedido</div>
                <div class="c2 s3 c-btn-description" style="margin-top: 2px">{{Util.Price(total.order)}}</div>
            </div>
        </div>
        
    </div>

    <modal v-model="currentTransaction" :closeable="false" class="modal-current-order">
 
        <div class="flex fill modal-current-transaction relative" v-if="currentTransaction">

            <div class="fill flex column modal-order" style="overflow-x: hidden">
			
                <transition enter-active-class="animated fadeIn faster">
                <div :class="`fill flex column flex-fill transaction-summary absolute`" style="width: 100%; max-width: 100%;" v-show="showTransactionDetails">
                    
                    <div class="items-list" style="gap: 10px">

                        <div class="ta-center s3 c2" style="margin-bottom: 10px">Toca un producto para modificarlo o quitarlo</div>

                        <div :class="`btn itemUpdate flex column relative r-item r-item-${item.title.replace(/ /g, '')}`" v-for="(item, key) in myFilteredOrder" :key="key" :ref="`order-${key}`"
                        @click="!loadingAction ? EditItem(item, key) : null" :title="`Editar ${item.title}`">
                            <div class="flex">
                                <div class="cart-item-image r-item-image" :style="{'background-image': `url('${Util.img(item.image, 'products')}')`}"></div>
                                <div class="flex column r-item-info" style="width: 100%">
                                    <div class="ta-left s1 r-item-name" style="line-height: 1; margin-bottom: 2px">{{item.title}}</div>
                                    <div :class="`ta-left c2 s2 ${modifier.type} r-item-modifier`" v-for="(modifier, index) in Util.ModifiersList(item, true)" :key="`modifier-${item.id}-${index}`">{{modifier.value}}</div>
                                </div>
                                <div class="flex column" style="margin-left: 20px">
                                    <div :class="`ta-right s1 r-item-quantity`"><span class="s3 c2">x</span>{{Util.Number(item.quantity)}} <span class="c2 s3" v-if="typeof item.float_quantity == 'string'">{{item.float_quantity}}</span></div>
                                    <div class="flex-fill"></div>
                                    <div class="s1 ta-right r-item-total">{{Util.Price(item.total*item.quantity)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex column" style="padding: 20px;"  v-if="currentTransaction.status == 'CONFIRMATION'">
                        <div class="separator" style="margin-top: 0px"></div>
                        <div class="flex c1 s3 tc-subtotal" style="margin: 0px">
                            <div class="flex-fill tc-title">Subtotal</div>
                            <div class="tc-value">{{Util.Price(total.order)}}</div>
                        </div>
                        <div class="flex c1 s3 tc-round" style="margin: 0px" v-if="total.round">
                            <div class="flex-fill tc-title">Ley del Redondeo</div>
                            <div class="tc-value">{{Util.Price(total.round)}}</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="type == 'DELIVERY'">
                            <div class="flex-fill">Reparto</div>
                            <div v-if="total.delivery - total.deliveryDiscount">{{Util.Price(total.delivery - total.deliveryDiscount)}}</div>
                            <div v-else class="c1 bold">GRATIS</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="total.discount - total.deliveryDiscount">
                            <div class="flex-fill">Descuento</div>
                            <div>-{{Util.Price(total.discount - total.deliveryDiscount)}}</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="total.tip">
                            <div class="flex-fill">Propina</div>
                            <div>{{Util.Price(total.tip)}}</div>
                        </div>
                        <div class="flex c1 s1" style="margin-top: 5px">
                            <div class="flex-fill">Total</div>
                            <div>{{Util.Price(total.total)}}</div>
                        </div>

                        <div v-if="currentTransaction.status == 'CONFIRMATION'" class="flex transaction-actions unshrink" style="margin-top: 20px">
                            <div :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : currentTransaction=null" title="Serguir armando mi pedido">Volver</div>
                            <div :class="`btn invert btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : SendOrder()" title="Enviar pedido"><span v-if="!loadingAction">Finalizar Compra</span><i v-if="loadingAction" class="icon icon-spinner spin"></i></div>
                        </div>

                    </div>
                    <div class="flex column" style="padding-right: 20px; margin-top: 10px;"  v-else>
                        <div class="separator"></div>
                        <div class="flex c1 s3" style="margin: 0px">
                            <div class="flex-fill">Subtotal</div>
                            <div>{{Util.Price(currentTransaction.total.order)}}</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.round">
                            <div class="flex-fill">Ley del Redondeo</div>
                            <div>{{Util.Price(currentTransaction.total.round)}}</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.type == 'DELIVERY'">
                            <div class="flex-fill">Reparto</div>
                            <div v-if="currentTransaction.total.delivery - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0)">{{Util.Price(currentTransaction.total.delivery  - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0))}}</div>
                            <div v-else class="c1 bold">GRATIS</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.discount - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0)">
                            <div class="flex-fill">Descuento</div>
                            <div>-{{Util.Price(currentTransaction.total.discount - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0))}}</div>
                        </div>
                        <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.tip">
                            <div class="flex-fill">Propina</div>
                            <div>{{Util.Price(currentTransaction.total.tip)}}</div>
                        </div>
                        <div class="flex c1 s1" style="margin-top: 5px">
                            <div class="flex-fill">Total</div>
                            <div>{{Util.Price(currentTransaction.total.total)}}</div>
                        </div>
                    </div>
                </div>
                </transition>

                <!-- ESPERANDO QUE REALICES TRANSBANK POS -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="!showTransactionDetails && currentTransaction.status == 'WAITING_TRANSBANK_POS_PAYMENT'" class="absolute fill flex-fill flex column transaction-status status-online-payment" style="padding: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_payment" :src="Util.img(customization.image_order_payment, 'banners')" alt="" style="height: 50%">
                        <status-interactive v-else status="WAITING_TRANSBANK_POS_PAYMENT"></status-interactive>
                        <div class="s1" style="margin-top: 40px">Esperando Pago...</div>
                        <div class="s1">{{Util.Price(currentTransaction.total.total)}}</div>
                        <div class="s1 ta-center" style="margin-top: 20px; white-space: break-spaces" v-if="customization.status_order_payment">{{customization.status_order_payment}}</div>
                    </div>
                    <div class="flex" style="gap: 10px" v-if="isTest">
                        <div class="btn btn-hover flex-fill flex align-center justify-center" @click="OnSaleError()">Prueba Error Pago</div>
                        <div class="btn btn-hover flex-fill flex align-center justify-center" @click="OnSaleSuccess('TEST')">Prueba Pago Exitoso</div>
                    </div>
                </div>
                </transition>

                <!-- COMPLETADO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="!showTransactionDetails && currentTransaction.status == 'TRANSBANK_POS_PAYMENT_SUCCESS'" class="absolute fill flex-fill flex column transaction-status status-completed" style="padding: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_success" :src="Util.img(customization.image_order_success, 'banners')" alt="" style="height: 50%">
                        <status-interactive v-else status="COMPLETED"></status-interactive>
                        <div class="s1" style="margin-top: 40px">¡Pago exitoso!</div>
                        <div class="ta-center" style="margin-top: 20px">
                            <div class="s3">Número Pedido</div>
                            <div class="s1" style="margin-top: 0px; font-size: 36px">#{{currentTransaction.id_ticket}}</div>
                        </div>
                        <div class="s1 ta-center" style="margin-top: 20px; white-space: break-spaces" v-if="customization.status_order_success">{{customization.status_order_success}}</div>
                    </div>
                    <div class="flex transaction-actions">
                        <div class="btn btn-hover flex-fill" @click="Finish()">Volver</div>
                    </div>
                </div>
                </transition>

                <!-- ERROR -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="!showTransactionDetails && currentTransaction.status == 'TRANSBANK_POS_PAYMENT_ERROR'" class="absolute fill flex-fill flex column transaction-status status-canceled" style="padding: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_error" :src="Util.img(customization.image_order_error, 'banners')" alt="" style="height: 50%">
                        <status-interactive v-else status="CANCELLED"></status-interactive>
                        <div class="s1" style="margin-top: 40px">Ha ocurrido un error con el pago</div>
                        <div class="s1">{{Util.Price(currentTransaction.total.total)}}</div>
                        <div class="s1 ta-center" style="margin-top: 20px; white-space: break-spaces" v-if="customization.status_order_error">{{customization.status_order_error}}</div>
                    </div>
                    <div class="flex transaction-actions">
                        <div class="btn btn-hover flex-fill flex align-center justify-center" @click="Finish()">Volver</div>
                        <div class="btn invert btn-hover flex-fill flex align-center justify-center" @click="RetryPayment()">Reintentar Pago</div>
                    </div>
                </div>
                </transition>

            </div>

        </div>
    </modal>

    <!-- PIN -->
    <!--<button @click="pinType='SETTINGS';showPin=true" style="position: absolute; left: 0; top: 0; z-index: 99">Test</button>-->
    <transition leave-active-class="animated slideOutLeft faster" enter-active-class="animated slideInLeft faster">
    <div class="totem-pin" v-if="showPin">

        <div class="flex align-center" style="gap: 10px; font-size: 40px; margin-bottom: 20px; height: 50px">
            <div v-for="i in currentPin.length" :key="i">•</div>
        </div>

        <div class="flex column" style="gap: 10px">
            <div class="flex" style="gap: 10px">
                <div class="btn" @click="currentPin += '1'">1</div>
                <div class="btn" @click="currentPin += '2'">2</div>
                <div class="btn" @click="currentPin += '3'">3</div>
            </div>
            <div class="flex" style="gap: 10px">
                <div class="btn" @click="currentPin += '4'">4</div>
                <div class="btn" @click="currentPin += '5'">5</div>
                <div class="btn" @click="currentPin += '6'">6</div>
            </div>
            <div class="flex" style="gap: 10px">
                <div class="btn" @click="currentPin += '7'">7</div>
                <div class="btn" @click="currentPin += '8'">8</div>
                <div class="btn" @click="currentPin += '9'">9</div>
            </div>
            <div class="flex" style="gap: 10px">
                <div class="btn c2" @click="currentPin = currentPin.substr(0, currentPin.length-1)"><i class="icon icon-chevron-left"></i></div>
                <div class="btn" @click="currentPin += '0'">0</div>
                <div class="btn invert" @click="EnterPin"><i class="icon icon-enter"></i></div>
            </div>
        </div>
    </div>
    </transition>

    <!-- SPLASH -->
    <transition leave-active-class="animated slideOutLeft faster" enter-active-class="animated slideInLeft faster">
    <div class="splash-screen" v-show="showSplash" :style="{'background-image': splashScreen ? `url('${Util.img(splashScreen, 'banners')}` : ''}" @click="showSplash = false">

    </div>
    </transition>

    <!-- ORDER TYPE -->
    <transition leave-active-class="animated slideOutLeft faster" enter-active-class="animated slideInLeft faster">
    <div class="center-content type-selection-screen flex column justify-center align-center" v-show="showOrderType">
        
        <div class="absolute" style="left: 0; top: 0; width: 100px; height: 100px; background: var(--center-fc); opacity: 0.025; transform: rotate(45deg) translate(-70%);" @click="pinType='SETTINGS';showPin=true"></div>

        <div class="ta-center l-local-logo" v-if="local && local.logotype">
            <img :src="Util.img(local.logotype, 'logos')" style="width: 300px; margin: 50px" class="logo">
        </div>

        <div class="flex column justify-center align-center" v-if="acceptsServe && acceptsTakeaway">

            <div class="s1 ta-center" v-if="customization && customization.welcome_message" style="white-space: break-spaces">{{ customization.welcome_message }}</div>

            <div class="flex" style="margin-top: 30px; gap: 40px">
                <div class="btn s1 invert flex column" @click="type = 'SERVE'; showOrderType = false" style="padding: 40px">
                    <i class="icon icon-table" style="font-size: 60px; margin-bottom: 20px; transform: none"></i>
                    <div>Para Servir</div>
                </div>
                <div class="btn s1 invert flex column" @click="type = 'TAKEAWAY'; showOrderType = false" style="padding: 40px">
                    <i class="icon icon-paper-bag" style="font-size: 60px; margin-bottom: 20px; transform: none"></i>
                    <div>Para Llevar</div>
                </div>
            </div>

        </div>
        <div v-else class="btn s1 invert" @click="showOrderType = false">¡Comienza tu pedido!</div>

    </div>
    </transition>

    <modal v-model="modalCheckActivity" sizeAuto>
        <div class="fill" style="padding: 30px">
            <div class="s1 ta-center" style="margin-bottom: 20px">¿Estás ahí?</div>
            <div class="btn invert">Sí</div>
        </div>
    </modal>

    <loading-screen :loading="loading"></loading-screen>
    <analytics-controller ref="analytics" :dictItems="dictItems" :categories="categories" :total="total" :myOrder="myOrder"></analytics-controller>
    <modal-coupon ref="modalCoupon" @OnCoupon="SetCoupon($event)"></modal-coupon>
    <modal-tip ref="modalTip" @OnTip="SetTip($event)" :total="total" :currentTip="currentTip"></modal-tip>
    <transbank-pos-controller ref="transbankPOS"
    @OnSaleSuccess="OnSaleSuccess"
    @OnSaleError="OnSaleError">

        <div style="margin-bottom: 10px">
            <div class="flex">
                <div class="flex-fill">
                    <div class="s1">General</div>
                    <div v-if="screenSize">Tamaño pantalla: {{ screenSize.width }} x {{ screenSize.height }}</div>
                </div>
                <div class="btn" @click="ToggleFullScreen"><i class="icon icon-expand"></i></div>
            </div>
            <label>
                <input type="checkbox" v-model="isTest"> Modo Prueba
            </label>
        </div>

    </transbank-pos-controller>

</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.min.css";
import LoadingScreen from './common/LoadingScreen.vue';
import AnalyticsController from './common/AnalyticsController.vue';
import ModalCoupon from './common/ModalCoupon.vue';
import ModalTip from './common/ModalTip.vue';
import MenuItems from './common/MenuItems.vue';
import StatusInteractive from './common/StatusInteractive.vue'
import TransbankPosController from './common/TransbankPOSController.vue';

var moment = require('moment');
export default {
    components: {LoadingScreen, Swiper, SwiperSlide,
        AnalyticsController, ModalCoupon, ModalTip, MenuItems, StatusInteractive, TransbankPosController
    },
    data() {
        return {
			//@data loading <Boolean> indicador si estan cargando los datos del ecommerce
            loading: true,
            local: null,
            menu: null,
            today: null,
            schedules: null,
            myOrder: {},
            type: null,
            currentTransaction: null,
			loadingAction: false,
			orderComment: '',
            audioBell: null,
            audioBop: null,
            audioPaperRip: null,
            banner: null,
            showTransactionDetails: true,
            couponCode: '',
            coupon: null,
            moduleCoupons: false,
            ecommerceType: null,
            searchItem: '',
            searchFocused: false,
            timestamp: 0,
            dictItems: {},
            carousel: null,
            topItems: null,
            customization: null,
            OrderType: {
                TAKEAWAY: 'Pedido para retirar',
                DELIVERY: 'Pedido delivery',
                SHIPMENT: 'Encargo'
            },
            currentTip: 0,
            tipsEnabled: false,
            defaultTip: 0,
            categories: {},
            itemPriceDisplay: 'ITEM_PRICE',
            recommendProductsConfig: false,
            recommendProducts: null,
            arrayRecommendProducts : 0,
            menuDisplay: 'ALL',
            loadingBottom: false,
            simplifiedData : {},
            currentPin: '',
            pinType: 'LOGIN',
            showPin: false,
            accessCode: '',
            accessCodeOk: false,
            splashScreen: null,
            acceptsTakeaway: true,
            acceptsServe: true,
            showSplash: false,
            showOrderType: false,
            timerTick: null,
            tick: 0,
            modalCheckActivity: false,
            screenSize: null,
            isTest: false,
        }
    },
    mounted() {
        
        var momentES = moment();
        momentES.locale('es');
        if(typeof data == 'undefined')
        {
            //load data from query
            axios.get(`data`).then(res => {
                this.InitialSetup(res.data);    
            }).catch(err => {
                this.loading = 'ERROR';
            });
        }
        else
        {
            //load data embeded on page
            this.InitialSetup(data.data);
        }

        
        this.audioBell = new Audio(require('../assets/bells.mp4'));
        this.audioBop = new Audio(require('../assets/bop.mp4'));
        this.audioPaperRip = new Audio(require('../assets/paper_rip.mp4'));   

        this.timerTick = setInterval(this.Tick, 1000);

        this.screenSize = {width: window.screen.width, height: window.screen.height};

        if(process.env.NODE_ENV === 'production')
        {
            document.addEventListener("contextmenu", function (e) {
                e.preventDefault();
            });
        }

    },
    methods: {
        InitialSetup(data)
        {
            this.showPin = true;
            this.timestamp = data.timestamp;
            this.today = moment.unix(this.timestamp).format('dddd').toLowerCase();

            this.local = data.local;
            this.menu = data.menu;
            
            this.recommendProductsConfig = data.recommend_products_active;
            this.banner = data.customization.banner;
            this.ecommerceType = data.ecommerce_type;
            this.shipmentType = data.shipment_type;
            document.body.classList.add(this.ecommerceType);
            this.moduleCoupons = data.coupons;
            this.carousel = data.carousel;
            this.topItems = data.popular;
            this.customization = data.customization;
            this.tipsEnabled = data.tips;
            this.defaultTip = data.default_tip;
            if(data.categories) this.categories = data.categories;
            if(data.item_price_display) this.itemPriceDisplay = data.item_price_display;

            this.menuDisplay = data.menu_display;
            this.accessCode = data.access_code;
            this.splashScreen = data.splash_screen;
            this.acceptsTakeaway = data.accepts_takeaway;
            this.acceptsServe = data.accepts_serve;

            this.menu.forEach(section => {
                section.items.forEach(item => {
                    if(section.title != 'Productos con anticipación'){
                        const newItem = { ...item, section: section.title };
                        newItem['section_hide_delivery'] = section.hide_delivery;
                        newItem['section_hide_takeaway'] = section.hide_takeaway;
                        this.$set(this.dictItems, item.id, newItem);
                    }
                });
            });

            this.$nextTick(() => {
                this.loading = false;
            });

            this.Util.ApplyCustomization(data.customization);

           //Google Analytics
            this.$refs.analytics.SetupGoogleAnalytics(data.google_analytics);
            this.$refs.analytics.SetupFacebookPixel(data.facebook_pixel, data.facebook_access_token);

            let ResetTimer = () => {
                this.tick = 0;
                this.modalCheckActivity = false;
            };

            document.addEventListener('click', ResetTimer);
            document.addEventListener('touchstart', ResetTimer);
            
            this.Finish();

        },
        MenuClick(section)
        {
            this.searchItem = '';
            const escapedTarget = 'section'+section.title.replace(/ /g, '').replace(/[-\/\\^$*+?.()|[\]{}%]/g, '\\$&');

            setTimeout(() => {
                ScrollTo(document.querySelector(`#${escapedTarget}`), {
                    elementToScroll: this.$refs.panelCenter,
                    verticalOffset: -80,
                    maxDuration: 300
                });
            }, 10);

            if(section && section.items && section.items.length)
            {
                this.$refs.analytics.Trigger('view_item_list', section);
            }
            
        },
        async AddItem(item, editKey)
        {
            if(this.loadingBottom) return;
            if(editKey && item.key !== editKey && this.myOrder[editKey])
            {
                this.$delete(this.myOrder, editKey);
                this.$refs.analytics.Trigger('remove_from_cart', item);
            }

            let tmpItem = null;
            if(item.item && item.item.id) tmpItem = this.dictItems[item.item.id];
            
            let fullProduct = item.item && item.item.id? this.dictItems[item.item.id] : this.dictItems[item.key.split("-")[0]];
            if(fullProduct.inventory_references) this.loadingBottom = true;
            if(!this.myOrder[item.key])
            {
			    let section = this.menu.find( section => section.title == tmpItem.section);
                var quantity = parseInt(item.quantity * 1000) / 1000;

                if(fullProduct && fullProduct.max_quantity_enabled){
                    if( (quantity > fullProduct.max_quantity) || (this.orderByIdQuantity && quantity + this.orderByIdQuantity[fullProduct.id] > fullProduct.max_quantity))
                    {
                        this.loadingBottom = false;
                        return this.Error(`La cantidad máxima de ${item.item.title} en el pedido es ${fullProduct.max_quantity}`);
                    }
                }

                var itemData = {
                    id: item.item.id,
                    title: item.item.title,
                    image: item.item.image,
                    total: item.total,
                    modifiers: item.modifiers,
                    quantity: quantity,
                    comment: item.comment ? item.comment : null,
                    float_quantity: item.float_quantity,
                    combo: !!item.combo,
                    section: item.section,
                    anticipation: tmpItem.anticipation,
                    anticipation_time: tmpItem.anticipation_time,
                    key: item.key,
                    hide_delivery: tmpItem.hide_delivery? tmpItem.hide_delivery : 0,
                    hide_takeaway: tmpItem.hide_takeaway? tmpItem.hide_takeaway : 0,
                };
                if(item.item.overwrite) itemData.overwrite = true

                if(item.combo){
                    itemData.subitems = item.subitems;
                }else{
                    itemData.section_hide_delivery = section.hide_delivery;
                    itemData.section_hide_takeaway = section.hide_takeaway;
                    itemData.section = tmpItem.section;
                }

                const stock = await this.ValidateStock(fullProduct, Util.Copy(this.myOrder), Util.Copy(itemData));
                if(!stock) return;
                this.Util.ClearLink();

                this.$set(this.myOrder, item.key, itemData);
                this.$refs.analytics.Trigger('add_to_cart', item);
            }
            else
            {
                var quantity = this.myOrder[item.key].quantity + (parseInt(item.quantity * 1000) / 1000);
                if(editKey) quantity = parseInt(item.quantity * 1000) / 1000;
                let oldQuantity = this.myOrder[item.key].quantity;
                const subtract = quantity < oldQuantity? true : false;

                if(fullProduct && fullProduct.max_quantity_enabled){
                    if( (quantity > fullProduct.max_quantity) || (this.orderByIdQuantity && quantity - oldQuantity + this.orderByIdQuantity[fullProduct.id] > fullProduct.max_quantity) )
                    {
                        this.loadingBottom = false;
                        return this.Error(`La cantidad máxima de ${fullProduct.title} en el pedido es ${fullProduct.max_quantity}`);
                    } 
                }

                let stock = false;
                if(!subtract){
                    stock = await this.ValidateStock(fullProduct, Util.Copy(this.myOrder), Util.Copy(item), quantity);
                }
                
                this.loadingBottom = false;

                if(!subtract && !stock) return;
                this.Util.ClearLink();

                this.$set(this.myOrder[item.key], 'quantity', quantity);
                if(this.myOrder[item.key].quantity <= 0)
                {
                    this.$delete(this.myOrder, item.key);
                }
                this.$refs.analytics.Trigger('remove_from_cart', item);
            }

            this.$nextTick(() => {
                var el = this.$refs[`btn-continue`];
                if(el)
                {
                    el.classList.remove('itemUpdate');
                    setTimeout(() => {
                        el.classList.add("itemUpdate");
                    }, 1);
                }
            });

            this.$refs.menuItems.modalItem = null;
            this.$refs.menuItems.modalCombo = null;

        },
        EditItem(item, editKey)
        {
            var it = this.Util.Copy(this.dictItems[item.id]);
            it.quantity = item.quantity.toString().replace('.', ',');
            it.comment = item.comment;

            const queryParams = window.location.search;
            this.Util.ClearLink();

            const newUrl = `${window.location.origin}${window.location.pathname}${encodeURIComponent(item.title)}%20${queryParams}`;
            history.pushState(null, '', newUrl);

            function SelectModifiers(sourceItem, selectedModifiers)
            {
                var dictMods = {};
                selectedModifiers.forEach(modifier => {
                    var modKey = `${modifier.group}_${modifier.modifier_original ? modifier.modifier_original : modifier.title}`;
                    if(modifier.modifier_original)
                        dictMods[modKey] = modifier.modifier_replacement;
                    else
                        dictMods[modKey] = dictMods[modKey] ? dictMods[modKey]+1 : 1;
                });

                sourceItem.modifiers.forEach(group => {
                    group.modifiers.forEach(modifier => {
                        var modKey = `${group.title}_${modifier.title}`;
                        
                        if(!group.multi_select && dictMods[modKey])
                        { 
                            if(group.replacement)
                            {
                                var replacement = group.modifiers_replacement.find((option) => {
                                    return option.title == dictMods[modKey];
                                });
                                if(replacement)
                                {
                                    modifier.selected = true;
                                    modifier.replacement = replacement;
                                }
                            }
                            else
                            {
                                modifier.selected = true;
                            }
                        }
                        else if(group.multi_select && dictMods[modKey]) modifier.count = dictMods[modKey];
                    });
                });
            }

            
            it.edit_key = editKey;

            if(it.type == 0)
            {
                SelectModifiers(it, item.modifiers);
                //this.modalItem = it;
                this.$refs.menuItems.modalItem = it;
            }
            else if(it.type == 1)
            {
                for(var i = 0; i < it.products.length; i++)
                {
                    SelectModifiers(it.products[i], item.modifiers[i].modifiers)
                }
                //this.modalCombo = it;
                this.$refs.menuItems.modalCombo = it;
            }
        },
		OpenOrderConfirm()
		{
            this.currentTransaction = {
                orders: this.myOrder,
                total: this.total,
                orderComment: '',
                type: this.type,
                customerName: this.customerName,
                customerPhone: this.customerPhone,
                customerEmail: this.customerEmail,
                status: 'CONFIRMATION',
            };

            this.$refs.analytics.Trigger('begin_checkout', this.currentTransaction);

		},
        SendOrder()
        {

            var order = {
                order: this.myOrder,
                total: this.total,
                orderComment: this.orderComment,
                type: this.type,
            };
            this.loadingAction = true;
            
		 	axios.post('/totem/order', order).then(res => {
                console.log(res.data);
                if(res.data.error){
                    if(!res.data.removedItems) this.Error(res.data.reason ? res.data.reason : res.data.message);
                    if(res.data.removedItems){
                        let deletedItems = "";

                        res.data.removedItems.forEach( item => {
                            deletedItems += `<li>${item.title}</li>`;
                            this.$delete(this.myOrder, item.key);
                        });

                        if(deletedItems != ""){
                            this.Error(`<div class='mb10 bold ta-center'>Se quitaron items no disponibles</div><ul>${deletedItems}</ul>`);
                        }
                    }
                    this.loadingAction = false;
                    return;
                }
                
                this.showTransactionDetails = false;
                this.currentTransaction = res.data;
                this.loadingAction = false;

                //Reset things
                this.coupon = null;
                this.myOrder = {};

                if(!this.isTest)
                    this.$refs.transbankPOS.Sale(this.currentTransaction.total.total, this.currentTransaction.id);

                this.$refs.analytics.Trigger('purchase', res.data);

            }).catch(err => {
                this.Error(err);
				this.loadingAction = false;
            });

        },
        RetryPayment()
        {
            if(this.currentTransaction && this.currentTransaction.status == 'TRANSBANK_POS_PAYMENT_ERROR')
            {
                this.$set(this.currentTransaction, 'status', 'WAITING_TRANSBANK_POS_PAYMENT');
                if(!this.isTest) 
                    this.$refs.transbankPOS.Sale(this.currentTransaction.total.total, this.currentTransaction.id);
            }
        },
        Finish()
        {
            this.showSplash = !!this.splashScreen;
            this.showOrderType = (this.acceptsServe && this.acceptsTakeaway) || !this.splashScreen;
            if(this.acceptsTakeaway) this.type = 'TAKEAWAY';
            else if(this.acceptsServe) this.type = 'SERVE';
            this.showTransactionDetails = true;
            this.currentTransaction = null;
            this.tick = 0;
            this.modalCheckActivity = false;
            this.myOrder = {};
            if(this.$refs.menuItems) this.$refs.menuItems.CloseAll();
        },
        OpenModalTip()
        {
            this.$refs.modalTip.Open(this.currentTip);
        },
        SetTip(tip)
        {
            this.currentTip = tip;
        },
        validateAutoSchedule(minSchedule){
            axios.post(`/validate/schedule`, {minSchedule, type: this.type}).then(({data}) => {
                if(data){
                    const typeService = this.type == 'TAKEAWAY'? '_takeaway' : '';
                    if(data["shipment_start"+typeService]) this.schedules["shipment_start"+typeService] = data["shipment_start"+typeService];
                    if(data["shipment_end"+typeService]) this.schedules["shipment_end"+typeService] = data["shipment_end"+typeService];
                }
            }).catch( err => {
            });
        },
        SetCoupon(coupon) {
            this.coupon = coupon;
            this.$refs.analytics.Trigger('select_promotion', coupon.code);
        },
        OpenModalRecommendProducts(){
            this.recommendProducts = null;
            axios.post(`/order/relations`, { order: this.myOrder }).then(res => {
                let relations = res.data.products;
                let quantity_recommended = res.data.quantity_recommended
                let popular = res.data.top_items;
                let myOrderIDs = Object.keys(this.myOrder).map(item => item.split('-')[0]);;

                this.recommendProducts = [];

                if(this.arrayRecommendProducts == 0){

                    let typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';
                    let array_quantity_recommended = [];   

                    if(relations.length >= quantity_recommended){

                        relations.forEach(element => {
                            
                            let dictItem = this.dictItems[element.id];
                            if(dictItem && !dictItem[`section_${typeKey}`] && !dictItem.typeKey && !dictItem.unavailable && !(myOrderIDs.some(x => x == dictItem.id))){
                                array_quantity_recommended.push(dictItem)
                            } 
                        });
                        
                    }else{

                        let mergedArray = relations.concat(popular);
                        let sumArray = [];

                        mergedArray.forEach(element => {
                            let found = sumArray.some(item => item.id === element.id)
                            if(!found) {
                                sumArray.push(element);
                            }
                        });

                        sumArray.forEach(element => {
                            let dictItem = this.dictItems[element.id];
                            if(dictItem && !dictItem[`section_${typeKey}`] && !dictItem.typeKey && !dictItem.unavailable && !(myOrderIDs.some(x => x == dictItem.id))){
                                array_quantity_recommended.push(dictItem)
                            } 
                        });

                    }

                    this.recommendProducts = array_quantity_recommended.slice(0, quantity_recommended);

                /*
                    for (let key in this.myOrder) {
                        if (this.myOrder.hasOwnProperty(key)) {
                            let itemOrder = this.myOrder[key];
                            myOrderIDs[itemOrder.id] = true;
                            if(relations[itemOrder.title]){
                            
                                let arrRelation = Object.entries(relations[itemOrder.title]);
                                console.log(arrRelation);
                                arrRelation.forEach(([title, item]) => {
                                    let dictItem = this.dictItems[item.id];
                                    if(dictItem && !dictItem['section_' + typeKey] && !item[typeKey] && !item.unavailable && dictItem.id != itemOrder.id)
                                        this.$set(this.recommendProducts, title, item);
                                });

                            }
                        }
                    }

                    if(Object.keys(this.recommendProducts).length < 5){
                        for (let key in popular) {
                            let item = popular[key];

                            let dictItem = this.dictItems[item.id];
                            if(dictItem && !dictItem['section_' + typeKey] && !item['section_' + typeKey] && !item.unavailable && !myOrderIDs[dictItem.id])
                                this.$set(this.recommendProducts, key, item);

                            if(Object.keys(this.recommendProducts).length >= 5) break;
                        }
                    }

                    */
                }else{
  
                    relations.forEach(element => {
                        if(!(myOrderIDs.some(x => x == element.id))){
                            this.recommendProducts.push(element)
                        } 
                    });
                }
            }).catch( err => {
              
            });
        },
        async ValidateStock(fullItem, order, item, quantity){
            if (quantity) {
                order[item.key].quantity = quantity;
                item = order[item.key];
            } else {
                order[item.key] = item;
            }

            const hasRequiredInventoryReferences = (references) => {
                return references.some(ref => ref.required);
            };

            let mainInventoryRequired = null;
            if(fullItem.inventory_references.length) mainInventoryRequired = hasRequiredInventoryReferences(fullItem.inventory_references);

            let subInventoryRequired  = null;
            if (item.combo) {
                subInventoryRequired = item.subitems.some(subitem =>
                    subitem.modifiers.some(modifier =>{
                        return modifier.inventory_references && hasRequiredInventoryReferences(modifier.inventory_references)
                    })
                );
            } else {
                if (item.modifiers.length > 0) {
                    subInventoryRequired = item.modifiers.some(modifier =>{
                        return modifier.inventory_references && hasRequiredInventoryReferences(modifier.inventory_references);
                    });
                }
            }

            if ((mainInventoryRequired || subInventoryRequired )) {
                const key = item.key;

                const newOrder = {};
                Object.keys(order).forEach(key => {
                    const item = order[key];
                    newOrder[key] = {
                        id: item.id,
                        title: item.title,
                        inventory_references: item.inventory_references || [],
                        float_quantity: item.float_quantity,
                        modifiers: item.modifiers,
                        quantity: item.quantity
                    };
                });

                try {
                    await axios.post(`/order/stock`, { order: newOrder });
                    this.loadingBottom = false;
                    return true;
                } catch (err) {
                    this.Error(err);
                    this.loadingBottom = false;
                    return false;
                }
            } else {
                this.loadingBottom = false;
                return true;
            }
        },
        OpenModalCoupon(){   
            this.$refs.modalCoupon.Open()
        },
        CheckIfItemIsUniquePerOrder(item) {
            if(!item.max_quantity_enabled || item.max_quantity !== 1) return null;
            for(let key in this.myOrder)
            {
                if(this.myOrder[key].id === item.id) return () => {
                    this.EditItem(this.myOrder[key], key);
                }
            }
            return null;
        },
        OnSaleSuccess(res) {
            if(this.currentTransaction)
            {
                this.tick = 0;
                if(res === 'TEST') res = {functionCode:210,responseCode:0,commerceCode:'000000000000',terminalId:"IT000000",responseMessage:"Aprobado",successful:true,ticket:"000000",authorizationCode:"000000",amount:this.currentTransaction.total.total,sharesNumber:"00",sharesAmount:"",last4Digits:'0000',operationNumber:"000000",cardType:"CR",accountingDate:"000000",accountNumber:"0000000000000000000",cardBrand:"MC",realDate:"01012025",realTime:"000000",employeeId:"",tip:null};
                axios.post(`/totem/payment`, {code: this.currentTransaction.code, payment: res}).then(res => {
                    this.tick = 0;
                    this.$set(this.currentTransaction, 'status', 'TRANSBANK_POS_PAYMENT_SUCCESS');
                    this.$set(this.currentTransaction, 'id_ticket', res.data.id_ticket.toString().slice(-this.customization.ticket_number_quantity));
                    console.log(res.data);
                    let arr = [
                        {text: 'Número de pedido', align: 'CT'},
                        {text: this.currentTransaction.id_ticket, width: 2, height: 2, align: 'CT'},
                        {line: true}
                    ];
                    arr.push({text: ['Fecha', res.data.date]});
                    res.data.items.forEach(line => {
                        if(line.type == 'item')
                            arr.push({text: [line.quantity.padEnd(3) + line.title, line.total_order]});
                        else if(line.type == 'group')
                            arr.push({text: '   '+line.title});
                        else if(line.type == 'modifier')
                            arr.push({text: '   '+line.title});
                    });
                    arr.push({line: true});
                    arr.push({text: ['Subtotal', res.data.subtotal]});
                    arr.push({text: ['Total', res.data.total]});
                    this.Util.PrintService(arr);
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        OnSaleError(err) {
            console.log(err);
            if(this.currentTransaction) 
            {
                this.tick = 0;
                this.$set(this.currentTransaction, 'status', 'TRANSBANK_POS_PAYMENT_ERROR');
            }
        },
        EnterPin()
        {
            if(this.pinType == 'LOGIN')
            {
                let pin = this.currentPin;
                axios.post(`/totem/login`, {code: pin}).then(res => {
                    this.accessCode = pin;
                    this.accessCodeOk = true;
                    this.showPin = false;
                }).catch(err => {
                    this.currentPin = '';
                    this.Error('Código incorrecto');
                });
            }
            else if(this.pinType == 'SETTINGS')
            {
                if(this.currentPin == '') return this.showPin = false;
                if(this.currentPin !== this.accessCode)
                {
                    this.currentPin = '';
                    this.Error('Código incorrecto');
                    return 
                } 
                this.showPin = false;
                this.$refs.transbankPOS.Open(this.currentPin);
            }
            this.currentPin = '';
        },
        Tick()
        {
            this.timestamp++;
            if(this.showSplash || (this.showOrderType && !this.splashScreen)) return;
            if(this.showPin || (this.$refs.transbankPOS && this.$refs.transbankPOS.isOpen)) return;
            this.tick++;

            if(this.splashScreen && !this.showSplash && this.showOrderType)
            {
                if(this.tick > 30) return this.Finish();
                return;
            }

            if(!this.currentTransaction || this.currentTransaction.status == 'CONFIRMATION')
            {
                if(this.tick == 120) return this.modalCheckActivity = true;
                if(this.modalCheckActivity && this.tick >= 150) return this.Finish();
            }
            else if(['TRANSBANK_POS_PAYMENT_ERROR', 'TRANSBANK_POS_PAYMENT_SUCCESS'].indexOf(this.currentTransaction.status) > -1)
            {
                if(this.tick >= 30) return this.Finish();
            }
        },
        ToggleFullScreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        },
    },
    computed: {
        total() {
            var subtotal = 0;
            var items = 0;
            var itemsList = [];
            for(var key in this.myOrder)
            {
                var item = this.myOrder[key];
                subtotal += item.total * item.quantity;
                items += item.quantity;

                if(item.float_quantity)
                    itemsList.push(item);
                else
                {
                    for(var i = 0; i < item.quantity; i++)
                    {
                        itemsList.push(item);
                    }
                }

            }

            subtotal = parseInt(subtotal);
            var deliveryCost = 0;
            
            var discount = 0;
            var couponUsed = null;
            var discounts = [];
            var deliveryDiscount = 0;

            var maxDiscount = subtotal;

            //check si el item esta en el cupon
            var CheckItemInCoupon = (item) => {
                var couponItem = null;
                if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                else
                {
                    couponItem = this.coupon.items.find(itemCoupon => {
                        return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                            return !!item.modifiers.find(modItem => {
                                return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                            });
                        });
                    });
                }
                return couponItem;
            }

            //checkear cupon de descuento
            if(this.coupon)
            {

                //TODO: schedule only if coupon is available always
                var day = moment().format('dddd').toLowerCase();
                var time = this.Util.TimeToMinutes(moment.unix(this.timestamp).format('HH:mm'));
                var timeStart = this.Util.TimeToMinutes(this.coupon[day+'_start']);
                var timeFinish = this.Util.TimeToMinutes(this.coupon[day+'_finish']);
                
                if(subtotal >= this.coupon.minimun &&
                    (this.coupon.sale == 'ANY' || this.coupon.sale == this.type) &&
                    !!this.coupon[day] && (time >= timeStart && time <= timeFinish)
                )
                {

                    var onTotal = this.coupon.discount.indexOf('ITEM') == -1;
                    var onAllSelectedItems = !onTotal && this.coupon.items_rule.indexOf('_SELECTION') > -1;

                    itemsList.sort((a, b) => {
                        return onAllSelectedItems ? (b.total - a.total) : (b.id == a.id ? b.total - a.total : a.id - b.id);
                    });

                    for (let key in this.coupon.sections) {
                        const [number, category] = key.split('-');
                        if(!category) break;
                        number = number.replace(/[{}]/g, '');
                        if(this.local.id == number) this.simplifiedData[category] =  this.coupon.sections[key]
                    }
                    if(Object.keys(this.simplifiedData).length > 0) this.coupon.sections = this.simplifiedData
                         
                    
                    if(onTotal)
                    {

                        var doesApply = true;
                        if((this.coupon.items && this.coupon.items.length) || this.coupon.sections)
                        {
                            if(this.coupon.items_rule == 'ALL')
                            {
                                for(var i = 0; i < itemsList.length; i++)
                                {
                                    var exists = CheckItemInCoupon(itemsList[i]);
                                    if(!exists)
                                    {
                                        doesApply = false;
                                        break;
                                    }
                                }
                            }
                            else if(this.coupon.items_rule == 'SOME')
                            {
                                doesApply = false;
                                for(var i = 0; i < itemsList.length; i++)
                                {
                                    var exists = CheckItemInCoupon(itemsList[i]);
                                    if(exists)
                                    {
                                        doesApply = true;
                                        break;
                                    }
                                }
                            }
                        }

                        if(doesApply)
                        {
                            switch(this.coupon.discount)
                            {
                                case '$':
                                    discount = this.coupon.value;
                                    break;
                                case '%':
                                    discount = parseInt((subtotal + (this.coupon.include_delivery ? deliveryCost : 0)) * this.coupon.value / 100);
                                    break;
                                case 'DELIVERY':
                                    discount = deliveryCost;
                            }
                            
                            maxDiscount = subtotal + (this.coupon.include_delivery ? deliveryCost : 0);
                            if(discount > maxDiscount) discount = maxDiscount;

                            couponUsed = this.coupon.id;
                            discounts.push({
                                title: `Código ${this.coupon.code}`,
                                discount: discount,
                                id_coupon_code: this.coupon.id_coupon_code
                            });
                        }
                    }
                    else
                    {
                        //descuento sobre items
                        var amount = 0;
                        var itemIndex = 0;
                        var lastItem = null;
                        for(var i = 0; i < itemsList.length; i++)
                        {
                            var doesApply = true;
                            var item = itemsList[i];

                            if(!!this.coupon.not_apply_discount && item.overwrite){
                                doesApply = false
                            }

                            if(!onAllSelectedItems)
                            {
                                itemIndex++;
                                
                                if(lastItem == null || lastItem.id !== item.id)
                                {
                                    lastItem = item;
                                    itemIndex = 1;
                                }
                            }

                            //check si el item esta en el cupon
                            var couponItem = null;
                            if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                            else
                            {
                                couponItem = this.coupon.items.find(itemCoupon => {
                                    return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                                        return !!item.modifiers.find(modItem => {
                                            return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                                        });
                                    });
                                });
                            }

                            if(onAllSelectedItems && couponItem) itemIndex++;

                            if(this.coupon.items_rule.indexOf('INDEX') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('INDEX_', '').replace('_SELECTION', ''));
                                if(itemIndex != index) doesApply = false;
                            }
                            else if(this.coupon.items_rule.indexOf('EVERY') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('EVERY_', '').replace('_SELECTION', ''));
                                if(itemIndex % index != 0) doesApply = false;
                            }

                            if(couponItem && doesApply)
                            {
                                let ow_modifiers = [] 
                                item.modifiers.forEach(element => {if(element.overwrite) ow_modifiers.push(element)})
                                
                                var includeModifiers = !!couponItem.include_modifiers;
                                var modifiersTotal = 0;
                                item.modifiers.forEach(mod => modifiersTotal += mod.price);
                                var price = includeModifiers ? item.total : (item.total - modifiersTotal);

                                if(this.coupon.not_apply_discount && includeModifiers){
                                    let ow_price = 0
                                    ow_modifiers.forEach(element => {
                                        ow_price += element.price 
                                    });
                                    price = price - ow_price
                                }
                                if(this.coupon.discount == 'ITEM_%')
                                {
                                    amount += parseInt(price * this.coupon.value / 100);
                                }
                                else if(this.coupon.discount == 'ITEM_MINUS')
                                {
                                    amount += this.coupon.value;
                                }
                                else if(this.coupon.discount == 'ITEM_$')
                                {
                                    amount += Math.max(0, price - this.coupon.value);
                                }
                            }
                        }

                        if(amount > 0)
                        {
                            discount = amount;
                            couponUsed = this.coupon.id;
                            discounts.push({
                                title: `Código ${this.coupon.code}`,
                                discount: discount,
                                id_coupon_code: this.coupon.id_coupon_code
                            });
                        }
                    }
                }
            }

            if(discount > maxDiscount) discount = maxDiscount;

            var tip = 0;
            if(this.tipsEnabled)
            {
                if(this.defaultTip)
                {
                    tip = parseInt(subtotal * this.defaultTip / 100);
                }
                else
                {
                    tip = parseInt(this.currentTip);
                    if(!tip || tip < 0) tip = 0;
                }
            }

            //cerrar modal si se quitan todos los items del pedido en el resumen
            if(this.currentTransaction && !this.currentTransaction.id && subtotal <= 0) this.currentTransaction = null;
            
            let round = 0;

            return {
                items: items,
                order: subtotal,
                delivery: deliveryCost,
                total: subtotal + deliveryCost + round - discount + tip,
                discount: discount,
                benefit: 'none',
                id_benefit: null,
                round: round,
                couponUsed: couponUsed,
                discounts: discounts,
                tip: tip,
                deliveryDiscount: deliveryDiscount
            }
        },
		canOrder() {
			if(this.total.items <= 0) return {reason: 'Debes agregar por lo menos 1 ítem'};
            if(this.total.order <= 0) return {reason: 'El costo total del pedido no puede ser $0'};
			return true;
		},
        filteredMenu(){
            if(!this.menu) return;
            if(!this.type) return this.menu;

            let ret = Util.Copy(this.menu);

            if(this.topItems.length)
            {
                let sectionTopItems = {
                    items: this.topItems,
                    title: 'Más Vendidos',
                    hideOnSearch: true
                };
                ret.unshift(sectionTopItems);
            }

            const typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';

            const filteredSections = ret.filter(section => !section[typeKey]);
            filteredSections.forEach(section => {
                section.items = section.items.filter(item => {
                    if (item.type == 0) {
                        return !item[typeKey];
                    } else if (item.type == 1) {
                        let countSubitems = item.products;
                        item.products = item.products.filter(subitem => {
                            let tmpSubitem = this.dictItems[subitem.id];
                            if(!tmpSubitem) return false;
                            return !(tmpSubitem['section_' + typeKey]) && !subitem[typeKey];
                        });
                        return item.products.length == countSubitems.length;
                    }
                });
            });

            return filteredSections;
        },
        myFilteredOrder(){
            if(!this.myOrder) return;
            if(!this.type) return this.myOrder;

            let items = Object.values(this.myOrder);

            let deletedItems = "";

            const typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';

            items.forEach( item => {
                if(item.combo){
                    let deleteCombo = false;
                    item.subitems.forEach(subitem => {
                        let tmpSubitem = this.dictItems[subitem.item.id];
                        if (!tmpSubitem){
                            deleteCombo = true;
                            return;
                        }
                        if (tmpSubitem['section_' + typeKey] || (!tmpSubitem['section_' + typeKey] && tmpSubitem[typeKey])) deleteCombo = true;
                    });
                    if(deleteCombo){
                        deletedItems += `<li>${item.title}</li>`;
                        this.$delete(this.myOrder, item.key);
                    }
                }else{
                    if (item['section_' + typeKey] || (!item['section_' + typeKey] && item[typeKey])) {
                        deletedItems += `<li>${item.title}</li>`;
                        this.$delete(this.myOrder, item.key);
                    }
                }
            });

            if(deletedItems != "") this.Error(`<div class='mb10 bold ta-center'>Se quitaron items no disponibles en ${this.OrderType[this.type]}</div><ul>${deletedItems}</ul>`);

            return this.myOrder;
        },
        minDate(){
            if(!this.myOrder) return;
            if(!Object.values(this.myOrder).length) return;

            let maxAnticipationTime = 0;
            let tmpDateStart = null;
            let dateStart = null;

            let anticipation = false;
            let countHaveAnticipation = 0;
            if(Object.values(this.myOrder).length){

                for (let key in this.myOrder) {
                    if (this.myOrder.hasOwnProperty(key)) {
                        const item = this.myOrder[key];

                        if(item.anticipation){
                            anticipation = true;
                            countHaveAnticipation++;
                        }

                        if (item.anticipation_time > maxAnticipationTime) maxAnticipationTime = item.anticipation_time;
                    }
                }
                tmpDateStart = moment.unix(this.timestamp).add(maxAnticipationTime, 'hours');
                dateStart = moment(tmpDateStart);
            }

            return {
                'anticipation': anticipation,
                'dateStart' : tmpDateStart,
                'maxAnticipationTime' : anticipation? maxAnticipationTime : null,
                'dateStartParse' : anticipation && dateStart? dateStart.format('DD/MM/YYYY HH:mm') : null
            };
        },
        orderByIdQuantity(){
            if(!this.myOrder || !Object.keys(this.myOrder).length) return;
			const groupedData = {};

            for(let index in this.myOrder){
                if(this.myOrder.hasOwnProperty(index)){
                    const tmpItem = this.myOrder[index];
                    const id = tmpItem.id;
                    const quantity = tmpItem.quantity;

                    if (!groupedData[id]) {
                        groupedData[id] = 0;
                    }
                    
                    groupedData[id] += quantity;
                }
            }

            return groupedData;
        },
    },
    watch: {

    },
}
</script>

<style lang="scss">
.TOTEM {
    * {user-select: none; }

    .it-item-image-small:not(.it-subitem-image-small) {
        width: 200px !important;
        height: 200px !important;
        margin-right: 20px !important;
    }

    .s1 {
        font-size: 24px;
    }

    .it-item-title {
        margin-bottom: 10px;
    }

    .mp-modifier-title {
        font-size: 20px;
    }

    .items-list {
        padding: 20px;
    }

    .transaction-summary {
        padding: 0px;
    }

    .cart-item-image {
        width: 100px;
        height: 100px;
    }

    .label-quantity {
        display: none;
    }
    .input-quantity {
        padding: 20px !important;
    }
    .it-combo-modifier {
        font-size: 18px;
    }

}
.main-container.totem {
    height: calc(100% - 120px);
}
.totem {
    .center-content {
        margin-right: 0px !important;
        margin-left: 250px !important;
    }
}
.totem-pin {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
}

.splash-screen {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 99;
}

.type-selection-screen {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--center-bg);
    z-index: 98;
    margin-left: 0px;
}

.bottom-checkout {
    position: absolute;
    bottom: 0;
    left: 0; 
    right: 0;
    z-index: 2; 
    overflow: hidden;
    height: 120px;
    width: 100%;
    top: unset;
    padding: 20px;
    display: flex;
    flex-direction: row !important;
}

label {
    display: flex;
    align-items: center;
    font-size: 16px;
    input[type="checkbox"] {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}

</style>