<template>
<div :class="`flex column mp-group ${Util.GroupRequirements(group).error ? 'selection-required' : ''}`">
    <div class="flex-fill s1 c1 mp-header" style="margin-bottom: 5px; text-align: left">
        <span v-show="!showExpanded" class="mp-title" style="margin-right: 5px">{{Util.TranslateLanguage(group.title)}}</span>
        <span :class="`mp-modifier-required ${Util.GroupRequirements(group).error ? 'cn' : 'cy'}`">{{showExpanded ? Util.GroupRequirements(group).label.replace('(', '').replace(')', '') : Util.GroupRequirements(group).label}}</span>
    </div>

    <div class="flex column mp-modifiers-container" v-if="!group.expand || showExpanded">
        <div :class="`flex s2 mp-modifier ${modifier.unavailable ? 'c2 not-allowed modifier-unavailable' : (group.multi_select ? 'c1' : 'c1 txt-hover')}`" style="margin-top: 15px;"
        v-for="(modifier, indexModifier) in group.modifiers" :key="`modfigier-${indexModifier}`"
        @click="!modifier.unavailable && !group.multi_select ? SelectModifier(indexModifier) : null"
        :title="modifier.unavailable ? '' : `${Util.TranslateLanguage('Seleccionar')} ${Util.TranslateLanguage(modifier.title)}`">

            <div class="flex column flex-fill mp-modifier-container">
                <div class="flex align-center">
                    <div :class="`flex-fill ${modifier.selected || modifier.count ? 'cy' : ''} ${modifier.unavailable ? 'unavailable' : ''}`" style="text-align: left">
                        <span class="mp-modifier-title">{{Util.TranslateLanguage(modifier.title)}} <span v-if="modifier.replacement"> ⇄ {{Util.TranslateLanguage(modifier.replacement.title)}} </span></span>
                        <span v-if="(modifier.price + (modifier.replacement ? modifier.replacement.price : 0) || modifier.price_reduction) && !modifier.unavailable" class="modifier-price" style="margin-right: 5px">
                            <span v-if="modifier.price_reduction && !modifier.replacement" class="cn" style="text-decoration: line-through; margin-right: 4px">{{Util.Price(modifier.price_old)}} </span>
                            <span>{{Util.Price(modifier.price + (modifier.replacement ? modifier.replacement.price : 0))}} </span>
                        </span>
                    </div>
                    <div class="hide-virtual-menu mp-modifier-selection" style="margin-left: 5px">
                        <div v-if="group.multi_select" :class="`flex unshrink align-center mp-multi-select ${modifier.count ? 'cy' : ''}`">
                            <div @click="modifier.unavailable ? null : AddModifier(indexModifier, -1)" :class="`icon icon-minus-o ${modifier.unavailable ? '' : 'icon-hover'}`" style="font-size: 23px; line-height: 0"></div>
                            <div :class="`ta-center mp-multi-select-quantity`" style="width: 40px">{{modifier.count ? modifier.count : 0}}</div>
                            <div @click="modifier.unavailable ? null : AddModifier(indexModifier, 1)" :class="`icon icon-plus-o ${modifier.unavailable ? '' : 'icon-hover'}`" style="font-size: 23px; line-height: 0"></div>
                        </div>
                        <i v-else :class="`icon icon-${modifier.selected ? 'square-check cy' : 'square'}`" style="transform: scale(1.2)"></i>
                    </div>
                </div>
                <!-- <span v-if="modifier.unavailable" class="s3">(No Disponible)</span> -->
                <div v-if="!modifier.unavailable" style="text-align: left; margin-top: -3px" class="s3 mp-modifier-description">
                    
                    <span class="c2">{{Util.TranslateLanguage(modifier.description, null, true, idItem , modifier.title, group.title, true)}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex column ta-left mp-expand" v-else>
        <div class="s3" style="opacity: 0.5" v-if="!GroupSelectedModifiers(group).length">{{Util.TranslateLanguage('Seleccionar')}}</div>
        <div class="s2 cy" v-for="(modifier, i) in GroupSelectedModifiers(group)" :key="i" style="margin-top: 4px">
            <i class="icon icon-plus" style="font-size: 8px; margin-right: 10px;"></i>
            <span v-if="modifier.count" class="mp-expand-count" style="min-width: 30px; display: inline-block">{{modifier.count}}</span>
            <span class="mp-expand-title">{{Util.TranslateLanguage(modifier.title)}}</span>
            <span v-if="modifier.price" class="modifier-price" style="margin-left: 5px">{{Util.Price(modifier.price)}}</span>
        </div>
    </div>

    <modal v-model="modalReplacement" :title="modalReplacement && !hideModalTitle ? Util.TranslateLanguage(modalReplacement.group.title) : null" desktopHeight="450px" desktopWidth="350px" class="modal-replacement" :closeable="!hideModalTitle">
        
        <div v-if="modalReplacement" class="fill flex column ofy" style="padding: 20px;">
            <div class="s1 cy" style="margin-bottom: 10px;">{{Util.TranslateLanguage(modalReplacement.modifier.title)}}</div>

            <div :class="`flex column s2 ${modifier.unavailable ? 'c2 not-allowed' : 'c1 txt-hover'}`" style="margin-top: 8px;"
            v-for="(modifier, indexModifier) in modalReplacement.modifier.replacement_options" :key="`replacement-${indexModifier}`"
            @click="!modifier.unavailable ? SelectModifier(modalReplacement.modIndex, modifier) : null"
            :title="modifier.unavailable ? '' : `${Util.TranslateLanguage('Seleccionar')} ${Util.TranslateLanguage(modifier.title)}`">                
                <div class="flex">
                    <div class="flex flex-fill">
                        <div :class="`${modifier.selected ? 'cy' : ''} ${modifier.unavailable ? 'unavailable' : ''}`" style="text-align: left">
                            <span>{{Util.TranslateLanguage(modifier.title)}} </span>
                            <span v-if="(modifier.price || modifier.price_reduction) && !modifier.unavailable" class="modifier-price" style="margin-right: 5px">
                                <span v-if="modifier.price_reduction" class="cn" style="text-decoration: line-through;">{{Util.Price(modifier.price_old)}} </span>
                                <span>{{Util.Price(modifier.price)}} </span>
                            </span>
                        </div>
                    </div>
                    <div style="margin-left: 5px">
                        <i :class="`icon icon-${modifier.selected ? 'square-check cy' : 'square'}`" style="margin-right: 10px; transform: scale(1.2)"></i>
                    </div>
                </div>
                <div v-if="!modifier.unavailable" style="text-align: left;" class="s3">
                    <span class="s2">{{Util.TranslateLanguage(modifier.description, null, true, idItem , modifier.title, group.title, true)}}</span>
                </div>
            </div>
            
            <div class="flex-fill"></div>
            
        </div>

        <div class="flex column" style="padding: 0px 20px 20px 20px">
            <div @click="modalReplacement=null" :class="`btn btn-hover`" style="margin-top: 10px">{{Util.TranslateLanguage('Volver')}}</div>
        </div>
        
    </modal>

</div>
</template>

<script>


export default {
    name : 'ModifiersPanel',
    props: {
        group: null,
        idItem : null,
        showExpanded: false,
        hideModalTitle: false,
    },
    data() {
        return {
            modalReplacement: null,
        }  
    },
    methods: {
        SelectModifier(indexModifier, replacement)
        {
            var mod = this.group.modifiers[indexModifier];
            var isAdd = !mod.selected;
            var currentlySelected = 0;
            this.group.modifiers.forEach(modifier => {
                if(modifier.selected) currentlySelected++;
            });

            if((this.group.min == 1 && this.group.max == 1) || (!this.group.min && this.group.max == 1))
            {

                if(this.group.replacement && !replacement && isAdd)
                {
                    this.modalReplacement = {group: this.group, modifier: mod, modIndex: indexModifier};
                    return;
                }

                if(currentlySelected == 1)
                {
                    for(var i = 0; i < this.group.modifiers.length; i++)
                    {
                        this.$set(this.group.modifiers[i], 'selected', false); 
                        this.$delete(this.group.modifiers[i], 'replacement'); 
                    }
                }
                if(isAdd)
                {
                    this.$set(this.group.modifiers[indexModifier], 'selected', true);
                    if(replacement) 
                        this.$set(this.group.modifiers[indexModifier], 'replacement', replacement);
                    this.modalReplacement = null;
                }
            }
            else
            {
                if(isAdd && this.group.max > 0 && currentlySelected == this.group.max) return;

                if(this.group.replacement && !replacement && isAdd)
                {
                    this.modalReplacement = {group: this.group, modifier: mod, modIndex: indexModifier};
                    return;
                }

                if(mod.selected)
                {
                    this.$delete(this.group.modifiers[indexModifier], 'replacement');
                }

                this.$set(this.group.modifiers[indexModifier], 'selected', !mod.selected);
                if(replacement) 
                    this.$set(this.group.modifiers[indexModifier], 'replacement', replacement);
                this.modalReplacement = null;
            }
            
        },
        AddModifier(indexModifier, quantity)
        {
            if(!this.group.multi_select) return;
            var mod = this.group.modifiers[indexModifier];
            var isAdd = quantity > 0;
            var currentlySelected = 0;
            this.group.modifiers.forEach(modifier => {
                if(modifier.count) currentlySelected += modifier.count;
            });
            if(this.group.min == 1 && this.group.max == 1)
            {
                if(currentlySelected == 1)
                {
                    for(var i = 0; i < this.group.modifiers.length; i++)
                        this.$set(this.group.modifiers[i], 'count', 0);    
                }
                if(isAdd)
                    this.$set(this.group.modifiers[indexModifier], 'count', 1);
            }
            else
            {
                if(isAdd && this.group.max > 0 && currentlySelected == this.group.max) return;
                var newCount = Math.max(0, (mod.count ? mod.count : 0) + quantity);
                this.$set(this.group.modifiers[indexModifier], 'count', newCount);
            }
            
        },
        GroupSelectedModifiers(group) {
            var ret = [];
            group.modifiers.forEach(mod => {
                var title = '';
                var price = 0;
                var count = 0;
                if(mod.selected) 
                {
                    if(mod.replacement) 
                    {
                        title = `${mod.title} ⇄ ${mod.replacement.title}`;
                        price = mod.price + mod.replacement.price;
                    }
                    else
                    {
                        title = mod.title;
                        price = mod.price;
                    }
                }
                else if(mod.count) 
                {
                    //title = `(${mod.count}) ${mod.title}`;
                    title = mod.title;
                    count = mod.count;
                    price = mod.count * mod.price;
                }

                //if(price) title += ` (${this.Util.Price(price)})`;
                if(title)
                    ret.push({title: title, price: price, count: count});
            });
            return ret;
        },
    }
}
</script>

<style lang="scss">
.modifier-price {
    background-color: var(--modal-c1-o);
    color: var(--modal-c1);
    font-weight: bold;
    font-size: 14px;
    border-radius: var(--btn-border-radius);
    padding: 2.5px 6px;
}
</style>