<template>
<modal v-model="modalSchedule" :title="Util.TranslateLanguage('Horarios')" desktopWidth="500px">
    <!-- @start Horarios -->
    <div v-if="ecommerceType == 'RESTAURANT'" class="flex column" style="padding: 20px;">
        <div v-if="isDisabled" class="s1 cn ta-center" style="margin-bottom: 20px">{{Util.TranslateLanguage('¡Temporalmente no disponible!')}}</div>

        <div class="flex">
            <div class="flex-fill">
                <div v-if="!openDaysContinuous.same" class="s1" style="margin-bottom: 20px">Delivery <i class="icon icon-motorbike"></i></div>
                <div v-for="(d, day) in Util.dictDaysEnEs" :key="d">
                    <div :class="`${day == today ? 'cy' : 'c1'} s1`">{{Util.TranslateLanguage(d)}}</div> 
                    
                    <div v-if="!openDaysContinuous.DELIVERY[day].length" class="c2 s2">{{!openDaysContinuous.same ? Util.TranslateLanguage('No disponible') : Util.TranslateLanguage('Cerrado')}}</div>
                    <div v-else>
                        <div v-for="(schedule, index) in openDaysContinuous.DELIVERY[day]" :key="d+index" class="c1 s2">{{Util.convertTimeAmPm(PatchTime(schedule.start))}} {{ Util.TranslateLanguage('a') }} {{Util.convertTimeAmPm(PatchTime(schedule.finish))}} {{formatHour == 'am_pm'? '' : 'hrs'}}</div>
                    </div>
                    
                    
                    <div v-if="day !== 'sunday'" class="separator"></div>
                    
                </div>
            </div>
            <div class="flex-fill" v-if="!openDaysContinuous.same" style="margin-left: 10px;">
                <div class="s1" style="margin-bottom: 20px">{{Util.TranslateLanguage('Retirar')}} <i class="icon icon-paper-bag"></i></div>
                <div v-for="(d, day) in Util.dictDaysEnEs" :key="d">
                    <div :class="`${day == today ? 'cy' : 'c1'} s1`">{{Util.TranslateLanguage(d)}}</div> 
                    
                    <div v-if="!openDaysContinuous.TAKEAWAY[day].length" class="c2 s2">{{Util.TranslateLanguage('No disponible')}}</div>
                    <div v-else>
                        <div v-for="(schedule, index) in openDaysContinuous.TAKEAWAY[day]" :key="d+index" class="c1 s2">{{Util.convertTimeAmPm(PatchTime(schedule.start))}} {{ Util.TranslateLanguage('a') }} {{Util.convertTimeAmPm(PatchTime(schedule.finish))}} {{formatHour == 'am_pm'? '' : 'hrs'}}</div>
                    </div>
                    
                    
                    <div v-if="day !== 'sunday'" class="separator"></div>
                    
                </div>
            </div>
        </div>
    </div>
    <!-- @end Horarios -->
</modal>
</template>

<script>
export default {
    props: {
        ecommerceType: null,
        openDays: null,
        isDisabled: null,
        formatRange: null,
        formatHour: null,
        timePatch: null,
    },
    data() {
        return {
            modalSchedule: null,
            today: null
        }
    },
    methods: {
        Open() {
            var moment = require('moment');
            this.today = moment().format('dddd').toLowerCase();
            this.modalSchedule = true;
        },
        MergeSequentialDays(scheduleType, openDays){
            const schedule = openDays[scheduleType];
            const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

            for (let i = 0; i < daysOfWeek.length; i++) {
                const currentDay = daysOfWeek[i];
                const nextDay = daysOfWeek[(i + 1) % daysOfWeek.length];

                if ((schedule[currentDay] && schedule[currentDay].length) && (schedule[nextDay] && schedule[nextDay].length)) {
                    const indexFinish = schedule[currentDay].findIndex(item => item.finish == '23:59');
                    const indexStart = schedule[nextDay].findIndex(item => item.start == '00:00');

                    if (indexFinish != -1 && indexStart != -1) {
                        if (schedule[currentDay][indexFinish].finish == '23:59' && schedule[nextDay][indexStart].start == '00:00') {
                            schedule[currentDay][indexFinish].finish = schedule[nextDay][indexStart].finish;
                            schedule[nextDay].splice(indexStart, 1);
                        }
                    }
                }
            }

            openDays[scheduleType] = schedule;

            return openDays;
        },
        PatchTime(time) {
            if(!this.timePatch) return time;
            
            var minutes = this.Util.TimeToMinutes(time) + (this.timePatch * 60);

            return this.Util.MinutesToTime(minutes);
        },
    },
    computed: {
        openDaysContinuous(){
            if(!this.openDays) return;
            if(this.formatRange != 'continuous') return this.openDays;
            let schedules = this.Util.Copy(this.openDays);

            schedules = this.MergeSequentialDays('DELIVERY', schedules);
            schedules = this.MergeSequentialDays('TAKEAWAY', schedules);

            return schedules;
        },
    }
}
</script>

<style>

</style>