<template>
<div>
    
    <modal v-model="modalCoupon" :closeable="modalCoupon && !modalCoupon.loading" :title="Util.TranslateLanguage('Código de Descuento')" desktopWidth="400px" desktopHeight="500px">
        <div v-if="modalCoupon" class="fill flex column" style="padding: 20px; overflow-x: hidden">
            <div class="c1 s3" style="margin-bottom: 10px">{{Util.TranslateLanguage('Ingresa un código de un Cupón de Descuento o un Código Promocional')}}</div>
            <div class="btn input left relative">
                <div class="label left c2 s3">{{Util.TranslateLanguage('Código')}}</div>
                <input @keypress.enter="SearchCoupon(modalCoupon.code)" v-model="modalCoupon.code" :readonly="!!modalCoupon.loading" type="text" :placeholder="Util.TranslateLanguage('Código de cupón o promoción')" class="w-label left s2" style="text-transform: uppercase" :title="Util.TranslateLanguage('Código')">
                <i v-if="modalCoupon.loading" class=" c2 icon icon-spinner spin absolute" style="right: 20px; bottom: 20px; font-size: 25px"></i>
            </div>
            <div v-if="modalCoupon.error" class="c1 s3 ta-center" style="margin: 20px 0px">{{modalCoupon.error}}</div>
            <div class="flex-fill"></div>
            <div :class="`btn btn-hover ${!modalCoupon.code ? 'disabled' : 'invert'}`" @click="SearchCoupon(modalCoupon.code)" :title="Util.TranslateLanguage('Ingresar código')">{{Util.TranslateLanguage('Aceptar')}}</div>
        </div>
    </modal>

    <modal v-model="modalCouponDetails" :title="Util.TranslateLanguage('Detalles del Cupón')" desktopWidth="400px" desktopHeight="500px">
        <div class="fill flex column" style="padding: 20px; overflow: hidden" v-if="modalCouponDetails">
            <div>{{modalCouponDetails.info}} {{ !!modalCouponDetails.not_apply_discount ? Util.TranslateLanguage('(Sujeto a restricción horaria)') : null }}</div>
            <ul v-if="modalCouponDetails.sections" >
                <li v-for="(val, section) in modalCouponDetails.sections && Object.keys(sectionsLocal).length > 0 ? modalCouponDetails.sections : {}" :key="section">
                    <span>{{ Util.TranslateLanguage('Productos de la sección') }} {{ Util.TranslateLanguage(section) }}</span>

                </li>
            </ul>
            <ul v-if="modalCouponDetails.items" >
                <li v-for="(item, iCouponItem) in modalCouponDetails.items" :key="iCouponItem">
                    <span>{{item.title}} <span class="c2" style="font-size: 12px">({{item.include_modifiers ? Util.TranslateLanguage('dcto en precio base más extras') : Util.TranslateLanguage('dcto sólo en precio base')}})</span></span>
                    <ul v-if="item.modifiers.length">
                        <li v-for="(modifier, iModifier) in item.modifiers" :key="`${iCouponItem}-${iModifier}`">{{Util.TranslateLanguage('Que incluya')}} {{Util.TranslateLanguage(modifier.group)}} - {{Util.TranslateLanguage(modifier.title)}}</li>
                    </ul>
                </li>
            </ul>
        </div>
    </modal>

</div>
</template>

<script>

export default {
    data() {
        return {
            modalCoupon: null,
            modalCouponDetails: null,
            sectionsLocal : {}
        }
    },
    methods: {
        Open(code)
        {
            this.modalCoupon = {code: code ? code : ''};
        },
        OpenDetails(couponInfo, simplifiedData)
        {
            this.sectionsLocal = simplifiedData
            this.modalCouponDetails = couponInfo;
        },
        SearchCoupon(code)
        {
            if(!this.modalCoupon.code) return;
            if(this.modalCoupon.loading) return;
            
            this.$set(this.modalCoupon, 'error', null);
            this.$set(this.modalCoupon, 'loading', true);
            axios.post(`/coupon`, {code: code}).then(res => {
                this.modalCoupon = null;
                this.$emit('OnCoupon', this.CouponInfo(res.data));
            }).catch(err => {
             
                this.$set(this.modalCoupon, 'error', Util.TranslateLanguage('El código ingresado no existe o no está disponible'));
                this.$set(this.modalCoupon, 'loading', false);
            });
        },
        CouponInfo(coupon) 
        {
            var title = '';
            if(coupon.discount == '%')
                title = `${coupon.value}% de descuento (sobre el total)`;
            else if(coupon.discount == '$')
                title = `$${this.Util.Number(coupon.value)} de descuento (sobre el total)`;
            else if(coupon.discount == 'DELIVERY')
                title = 'Reparto Gratis';
            else if(coupon.discount == 'ITEM_%')
                title = `${coupon.value}% de descuento (en productos)`;
            else if(coupon.discount == 'ITEM_MINUS')
                title = `$${this.Util.Number(coupon.value)} de descuento (en productos)`;
            else if(coupon.discount == 'ITEM_$')
                title = `Productos a $${this.Util.Number(coupon.value)}`;

            var description = `${this.Util.TranslateLanguage('Código')} ${coupon.code}. `;
            if(coupon.minimun) description += `${this.Util.TranslateLanguage('Válido para compras mayores a')} $${this.Util.Number(coupon.minimun)}. `;
            if(coupon.method != 'ANY') description += `${this.Util.TranslateLanguage('Válido para compras con medio de pago')} ${this.Util.PayMethod(coupon.method)}. `;
            if(coupon.sale != 'ANY') description += `${this.Util.TranslateLanguage('Válido para compras para')} ${coupon.sale == 'DELIVERY' ? 'delivery' : (coupon.sale == 'SERVE' ? this.Util.TranslateLanguage('servir') : this.Util.TranslateLanguage('retirar'))}. `;
            
            var daysCount = 0;
            var validDays = '';
            var timeCondition = false;
            for(var day in this.Util.dictDaysEnEs)
            {
                if(coupon[day])
                {
                    daysCount++;
                    validDays += `, ${this.Util.dictDaysEnEs[day]}`;
                    if(coupon[`${day}_start`] !== '00:00' || coupon[`${day}_finish`] !== '23:59') 
                    {
                        timeCondition = true;
                        validDays += ` (${coupon[day+'_start']} - ${coupon[day+'_finish']} hrs)`;
                    }
                }
            }
            if(daysCount < 7 || timeCondition)
            description += this.Util.TranslateLanguage('Válido solo los') + ' ' + validDays.substring(2) + '. ';


            var itemInfo = false;
            if(coupon.discount.indexOf('ITEM') > -1)
            {
                itemInfo = true;
                switch(coupon.items_rule)
                {
                    case 'ALL':
                        description += Util.TranslateLanguage('Aplica a cada producto.')+' ';
                        break;
                    case 'INDEX_1':
                        description += Util.TranslateLanguage('Aplica al primero de cada producto.')+' ';
                        break;
                    case 'INDEX_2':
                        description += Util.TranslateLanguage('Aplica al segundo de cada producto.')+' ';
                        break;
                    case 'INDEX_3':
                        description += Util.TranslateLanguage('Aplica al tercero de cada producto.')+' ';
                        break;
                    case 'INDEX_4':
                        description += Util.TranslateLanguage('Aplica al cuarto de cada producto.')+' ';
                        break;
                    case 'EVERY_2':
                        description += Util.TranslateLanguage('Aplica cada dos productos iguales.')+' ';
                        break;
                    case 'EVERY_3':
                        description += Util.TranslateLanguage('Aplica cada tres productos iguales.')+' ';
                        break;
                    case 'INDEX_1_SELECTION':
                        description += Util.TranslateLanguage('Aplica al primer producto de los seleccionados.')+' ';
                        break;
                    case 'INDEX_2_SELECTION':
                        description += Util.TranslateLanguage('Aplica al segundo producto de los seleccionados.')+' ';
                        break;
                    case 'INDEX_3_SELECTION':
                        description += Util.TranslateLanguage('Aplica al tercer producto de los seleccionados.')+' ';
                        break;
                    case 'INDEX_4_SELECTION':
                        description += Util.TranslateLanguage('Aplica al cuarto producto de los seleccionados.')+' ';
                        break;
                    case 'EVERY_2_SELECTION':
                        description += Util.TranslateLanguage('Aplica cada dos productos de los seleccionados.')+' ';
                        break;
                    case 'EVERY_3_SELECTION':
                        description += Util.TranslateLanguage('Aplica cada tres productos de los seleccionados.')+' ';
                        break;
                }
                
            }
            else
            {
                if((coupon.items && coupon.items.length) || coupon.sections)
                {
                    itemInfo = true;
                    description += coupon.items_rule == 'ALL' ? Util.TranslateLanguage('Aplica si el pedido incluye todos los productos.')+' ' : Util.TranslateLanguage('Aplica si el pedido incluye al menos uno de los productos.')+' ';
                }
            }

            coupon.title = title;
            coupon.description = description;
            coupon.info = `${title}. ${description}`;
            coupon.itemInfo = itemInfo;

            return coupon;
        }
    }
}
</script>

<style>

</style>