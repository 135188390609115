import Vue from 'vue'
import App from './App.vue'
import ScrollTo from 'animated-scroll-to'
import VueSocketIO from 'vue-socket.io-extended'
import io from 'socket.io-client'

window.Util = require('./Util').default;
window.ScrollTo = ScrollTo;

const socket = io(process.env.VUE_APP_WS_URL, {
	autoConnect: false,
	//reconnectionAttempts: 5
});
window.Util.socket = socket;
Vue.use(VueSocketIO, socket);

Vue.config.productionTip = false;
Vue.component('main-view', require('./components/Main.vue').default);
Vue.component('modal', require('./components/common/Modal.vue').default);
Vue.component('franchise-view', require('./components/FranchiseView.vue').default);
Vue.component('virtual-menu-view', require('./components/VirtualMenu.vue').default);
Vue.component('totem-view', require('./components/TotemView.vue').default);

Vue.mixin({
	data() {
		return {
			Util: window.Util,
			selectedLanguage : null,
            superSectionsShow : [],
            superSectionSelected : null,
		}
	},
	methods: {
		Error(err) {
			return this.$root.$children[0].ShowError(err);
		},
		Languages(lang) {
			return this.$root.$children[0].ShowLanguages(lang);
		},
        OpenContact(phone)
        {
			if(!phone) return;
			var options = [];
			var phones = phone.split('-');
			phones.forEach(phone => {
				phone = phone.trim();
				options.push({text: `${this.Util.TranslateLanguage('Llamar')} ${phone}`, function: () => {this.Util.Open(`tel:${phone}`)}});
				if(phone.indexOf('+569') > -1) options.push({text: `${this.Util.TranslateLanguage('Abrir en Whatsapp')} (${phone})`, function: () => {this.Util.OpenWhatsapp(phone)}});
			});
			options.push({text: `${this.Util.TranslateLanguage('Cancelar')}`, function: () => {}});
			this.Error(options);
        },
		OpenLanguages(languages)
        {
			if(!languages) return;
			var options = [];

			// Si hay un idioma seleccionado, se añade "Español" a las opciones
			if (this.selectedLanguage) {
				options.push({ text: 'Español', function: () => {
					Util.MergeMenuObjects({}, 'Español')
					this.selectedLanguage = null;
					localStorage.removeItem('language');
				}})
			}
			
			languages.forEach(lang => {
				// SI el idioma ya está selccionado, no aparece en las opciones				
				if(!this.selectedLanguage || (this.selectedLanguage.name != lang) ) {
					options.push({text : lang, function : async () => {
						// Se hace una petición cada vez que se selecciona un idioma
						await axios.get(`/language/${lang}`).then(res => {
							this.selectedLanguage = res.data;
							localStorage.setItem('language', lang)
							Util.MergeMenuObjects(res.data.overwrites, res.data.name);
						}).catch(err => {
						
						});
				
					}});
				}
			});
			
			// Se añade la opción de "Cancelar"
			options.push({text: this.Util.TranslateLanguage(`Cancelar`), function: () => {}});
			this.Languages(options);
        },
		//Metodo utilizado para cuando hay un idioma en el localstorage, se ejecuta
		LoadLanguageFromLocalStorage(lang) {
			axios.get(`/language/${lang}`).then(res => {
				// Si se retorna un objeto vacio
				if(Object.entries(res.data).length == 0) {
					localStorage.removeItem('language'); // Se elimina en el localstorage
					this.selectedLanguage = null;
				}else {
					this.selectedLanguage = res.data;
					Util.MergeMenuObjects(res.data.overwrites, res.data.name);
				}
			}).catch(err => {
			
			});		
		}
	},
	computed: {
		mobile() {
			return this.$root.$children[0].$data.platform == 'mobile';
		},
		tablet() {
			return this.$root.$children[0].$data.isTablet;
		},
		view() {
			return this.$root.$children[0].$data.type;
		}
	}
});

new Vue({
  render: h => h(App),
}).$mount('#app')
