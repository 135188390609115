<template>
	<modal v-model="optionsLanguages" :sizeAuto="true" class="modal-error">
        <div style="padding: 20px" v-if="optionsLanguages">
            <div class="message" v-if="typeof optionsLanguages == 'string'" v-html="optionsLanguages"></div>
            <div v-if="Array.isArray(optionsLanguages)" class="flex column" style="margin-bottom: -10px">
                <div v-for="option in optionsLanguages" :key="typeof option == 'string' ? option : option.text" style="margin-bottom: 10px">
					<div v-if="typeof option == 'string'" class="message">{{option}}</div>
					<div v-else @click="option.function(); optionsLanguages = null;" class="btn btn-hover"> <img style="margin-right: 10px" :src="Util.GetImagePath(option.text)" v-if="option.text != Util.TranslateLanguage('Cancelar')"> {{option.text}}</div>
				</div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {

    props : {
        modalLanguages : {
            type : Array, default : null
        }
    },
    data() {
        return {
            optionsLanguages : null
        }
    }, 
    watch:{
        modalLanguages: function(n,o){
            this.optionsLanguages = n;
        }
    },
}

</script>